import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ClientSector } from 'types/client';

type ClientModalData = {
    isValid: boolean;
    isEdit: boolean;
}

export const initialClientModalData: ClientModalData = {
    isValid: false,
    isEdit: false
}

export const initialCurrentClient: ClientSector = {
    id: "",
    banner: "",
    client_name: "",
    email: "",
    Assigned_Bucket:"",
    external_contact: "",
    external_contact_phone_number: "",
    phone_number: "",
    salesforce_account: "",
    sectors: []
}

type InitialClientState = {
    showClientModal: boolean;
    clientModalData: ClientModalData,
    currentClient: ClientSector
}

const initialState: InitialClientState = {
    showClientModal: false,
    clientModalData: initialClientModalData,
    currentClient: initialCurrentClient
}

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setShowClientModal: (state, action: PayloadAction<boolean>) => {
            state.showClientModal = action.payload
        },
        setClientModalData: (state, action: PayloadAction<ClientModalData>) => {
            state.clientModalData = action.payload
        },
        setCurrentClient: (state, action: PayloadAction<ClientSector>) => {
            state.currentClient = action.payload
        },
    },
})

export const { setShowClientModal, setClientModalData, setCurrentClient } = clientSlice.actions

export default clientSlice.reducer

