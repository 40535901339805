const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  BASE_API_URL: `${process.env.REACT_APP_BASE_URL}/api/`,
  GEOSERVER_VALIDATION_FLAG: `${process.env.REACT_APP_BASE_URL}/api/analytic/`,
  ML_PREDICTION: `${process.env.REACT_APP_BASE_URL}/`,
  WFS_GEOSERVER: `${process.env.REACT_APP_BASE_URL}/api/geoserver/wfs`,
  WMS_GEOSERVER: `${process.env.REACT_APP_BASE_URL}/api/geoserver/wms`,
  GEOSERVER_LEGEND: `${process.env.REACT_APP_BASE_URL}/api/geoserver/graphic?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=`,
  AG_GRID_LICENSE: process.env.REACT_APP_AG_GRID_LICENSE ?? "",
  ENV: process.env.REACT_APP_ENV ?? "",
  BOLD_BI_SERVER_URL: process.env.REACT_APP_BOLD_BI_SERVER_URL,
  BOLD_BI_API_URL: `${process.env.REACT_APP_BOLD_BI_SERVER_URL}bi/api/`,
  LAYER_KEY: process.env.REACT_APP_LAYER_KEY
};

export default config;
