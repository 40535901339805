import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { FunctionalInstance, WidgetInstance, WidgetInstanceSelectableItem } from 'types/widget-instance';

type WidgetInstanceModalData = {
    widgetInstanceId: string;
    isValid: boolean;
    isEdit: boolean;
}

export const initialWidgetInstanceModalData: WidgetInstanceModalData = {
    widgetInstanceId: "",
    isValid: false,
    isEdit: false
}

type InitialWidgetInstanceState = {
    showWidgetInstanceModal: boolean;
    widgetInstanceModalData: WidgetInstanceModalData,
    widgetInstances: Array<WidgetInstanceSelectableItem>;
}

const initialState: InitialWidgetInstanceState = {
    showWidgetInstanceModal: false,
    widgetInstanceModalData: initialWidgetInstanceModalData,
    widgetInstances: [],
}

export const widgetInstanceSlice = createSlice({
    name: 'widgetInstance',
    initialState,
    reducers: {
        setShowWidgetInstanceModal: (state, action: PayloadAction<boolean>) => {
            state.showWidgetInstanceModal = action.payload
        },
        setWidgetInstanceModalData: (state, action: PayloadAction<WidgetInstanceModalData>) => {
            state.widgetInstanceModalData = action.payload
        },
        processWidgetInstances: (state, action: PayloadAction<Array<WidgetInstance>>) => {
            state.widgetInstances = action.payload.map((widgetInstance) => ({
                ...widgetInstance,
                isSelected: false,
                isDefault: false
            }))
        },
        updateWidgetInstancesByRole: (state, action: PayloadAction<Array<FunctionalInstance>>) => {
            state.widgetInstances = state.widgetInstances.map(
                (widgetInstance) => ({
                    ...widgetInstance,
                    isSelected: false,
                    isDefault: false,
                })
            );
            action.payload.forEach((item) => {
                state.widgetInstances.find((widgetInstance) => widgetInstance.id === item.id)!.isSelected = true
                state.widgetInstances.map((widgetInstance) => {
                    if (widgetInstance.id === item.id && item.is_default)
                        widgetInstance.isDefault = true;
                });
            })
        },
        updateWidgetInstanceSelectStatus: (state, action: PayloadAction<{ id: string, checked: boolean }>) => {
            const { id, checked } = action.payload
            state.widgetInstances.find((widgetInstance) => widgetInstance.id === id)!.isSelected = checked
        },
        updateWidgetDefaultInstance: (state, action: PayloadAction<{ id: string, checked: boolean }>) => {
            const { id, checked } = action.payload
            state.widgetInstances.map(
                (widgetInstance) => (widgetInstance.isDefault = false)
            );
            state.widgetInstances.find((widgetInstance) => widgetInstance.id === id)!.isDefault = checked
        },
    },
})

export const { setShowWidgetInstanceModal, setWidgetInstanceModalData, processWidgetInstances, updateWidgetInstanceSelectStatus, updateWidgetDefaultInstance, updateWidgetInstancesByRole } = widgetInstanceSlice.actions

export default widgetInstanceSlice.reducer

