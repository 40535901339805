import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


type InitialBucketManageState = {
    showBucketManageModal: boolean;
    showAssosciateModal: boolean;
    bucketDetails: {
        bucketName: string;
        bucketId: string;
    };
}

const initialState: InitialBucketManageState = {
    showBucketManageModal: false,
    showAssosciateModal: false,
    bucketDetails: {
        bucketName: '',
        bucketId: ''
    }
}

export const bucketManageSlice = createSlice({
    name: 'bucketManagement',
    initialState,
    reducers: {
        setShowBucketManageModal: (state, action: PayloadAction<boolean>) => {
            state.showBucketManageModal = action.payload
        },
        setShowAssosciateModal: (state, action: PayloadAction<boolean>) => {
            state.showAssosciateModal = action.payload
        },
        setBucketDetails: (state, action: PayloadAction<{ bucketName: string; bucketId: string }>) => {
            state.bucketDetails.bucketName = action.payload.bucketName;
            state.bucketDetails.bucketId = action.payload.bucketId;
        },

    },
})

export const {setShowBucketManageModal,setShowAssosciateModal,setBucketDetails} = bucketManageSlice.actions

export default bucketManageSlice.reducer